import React from "react"
import {graphql, navigate} from "gatsby"

import Layout from '../components/Layout'
import Metadata from '../components/Metadata'
import Rows from "../components/Rows"

import {GENRES, GENRES_TABLE, GENRES_TABLE_FR} from '../const'



// const sortByDate = movie => movie.sort((a, b) => new Date(a.movie.release) - new Date(b.movie.release))

const getLastReleased = movies => movies.sort((a,b) => new Date(b.movie.release) - new Date(a.movie.release)).filter(i => {
  // console.log(GENRES_TABLE[23])
  let date = new Date(i.movie.release)
  let now = Date.now()
  return date <= now
})

const getGenre = (genre, movies) => movies.filter(i => {
  if (!GENRES[genre]) return false
  if(!i.movie.genres) return false
  let num = GENRES[genre]
  num = num.toString()
  // console.log('Does ', i.movie.genres, 'includes ', num , i.movie.genres.includes(num))
  return i.movie.genres.includes(num)
})

const getPopular = movies => movies.sort((a,b) => b.analytics.views_last_month - a.analytics.views_last_month )

const getTrending = movies => movies.sort((a,b) => b.analytics.views_last_three_days - a.analytics.views_last_three_days )

const getHighAvgTimes = (movies) => movies.sort((a,b) => b.analytics.avg_time_on_page - a.analytics.avg_time_on_page)
const getHighAvgRatings = (movies) => movies.sort((a,b) => b.avg_rating - a.avg_rating)


const getStreaming = movies => movies.filter(i => {
  let hasMovie = false
  i.videos.forEach(video => {
    if (video.type === "movie") hasMovie = true
  })
  return hasMovie
})

class HomePage extends React.Component {
  state = {
    movies: [],
  }

  componentDidMount(){
    navigate("/browse/free")
  }

  render() {
    // let all = this.props.data.allInternalMovies.edges.map(i =>  i.node).filter(i => i.title !== null)
    // const allMovies = all
    // const allpopularMovies = getPopular([...allMovies])

    // const lastReleased = getLastReleased(allMovies).slice(0, 40)
    // const popular = allpopularMovies.slice(0, 40)
    // const highOnPageTime = getHighAvgTimes([...allMovies]).filter(i => !popular.slice(0,9).includes(i)).slice(0, 40)
    // const highAvgRating = getHighAvgRatings(allMovies).slice(0, 40)

    // const genres = {}
    // GENRES_TABLE.forEach(i => {
    //   genres[i] = getGenre(i, allpopularMovies)
    // })

    // let rowifiedGenres = []
    // for (let key in genres){
    //   if (key.length > 3 && genres[key].length > 2) {
    //     let title = GENRES_TABLE_FR[GENRES_TABLE.indexOf(key)]
    //     rowifiedGenres.push(
    //       <Rows movies={genres[key]} title={title} moreUrl={`films/meilleurs-films-${GENRES_TABLE_FR[GENRES_TABLE.indexOf(key)].toLowerCase()}-de-tous-les-temps`} /> // params={{genre: GENRES_TABLE.indexOf(key)}}
    //     )
    //   }
    // }

    return(
      <Layout>
        <Metadata title="Films, Dessins Animés et Documentaires Gratuits en Streaming" pathname={this.props.location.pathname}/>
        <Rows movies={null} title={'Les plus populaires'} params={{order: 'rating_number'}}/>
        <Rows movies={null} title={'Derniers ajouts'} params={{order: 'release_date'}}/>
        <Rows movies={null} title={'Dernières sorties'} params={{order: 'release_date'}}/>
        <Rows movies={null} title={'Films'} disabledMoreButton/>
        <Rows movies={null} title={'Dessins animés'} moreUrl="/films/meilleurs-films-de-tous-les-temps"/> {/* params={{order: 'avg_rating'}} */}
        <Rows movies={null} title={'Documentaires'} moreUrl="/films/meilleurs-films-de-tous-les-temps"/> {/* params={{order: 'avg_rating'}} */}
        {/* {rowifiedGenres} */}
      </Layout>
    )
  }
}

export default HomePage

// export const query = graphql`
// {
// 	allInternalMovies(
//     filter: {
//       movie:{genres: {regex: "//"}}
//     }
//   ){
//     edges {
//       node {
//         id
//         title
//         slug
//         language
//         synopsis
//         poster
//         poster_thumb {
//           x
//           y
//           url
//         }
//         avg_rating
//         videos {
//           type
//         }
//         analytics {
//           views_last_month
//           views_last_three_days
//           total_views
//           avg_time_on_page
//         }
//         movie {
//           alternative_id
//           original_title
//           original_language
//           original_country
//           budget
//           box_office
//           runtime
//           release
//           genres
//         }
//       }
//     }
//   }
// }
// `

/* 
  Rows on movie page under the video
  * People who have seen this video have also seen (if any)
    If not logged in, means -> Users that stayed long and played video on <Page> page also stayed long on <Page>

  Possible rows
  * Keep watching (if any)
  * Top picks(if any)
  * Popular on cinetimes
  * 5 personnalized genre rows
  * Because you watched <Video>
  * Users who watched <Video> also watched <Row of videos>
  * Oscar winners
  * Other movies with <Actor>
  *  Vos articles vus récemment et vos recommandations en vedette -- Inspiré par vos achats -- 
*/